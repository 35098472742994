import React, { Fragment } from "react";
import { Link } from "gatsby";
import Footer from "../homepage/footer";
import Lottie from "react-lottie";
import logoAnimationData from "../homepage/logo_animation.json";
import Seo from "../../seo";
import "../speakerdetails/styles.scss";
import { scheduleEventsDetails } from "../homepage/allstate.js";
import brand from "../homepage/images/logo.svg";
import arrow from "../homepage/images/enterprise-arrow.svg";
import speakersIllustration from "../homepage/images/speakers-illustration.svg";
import btnArrow from "../homepage/images/btn-arrow.svg";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf.jpg",
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: logoAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const TalkDetails = props => {
  const talkUrl = props.pageContext.slug;
  const currentTalkFilter = scheduleEventsDetails.filter(b => b.url === talkUrl);
  const currentTalks = currentTalkFilter[0];
  if (!currentTalks) {
    if (typeof window !== undefined) {
      window.location.href = "/404";
    }
  }
  return (
    <Fragment>
      <Seo
        title={currentTalks.metaTags.title}
        description={currentTalks.metaTags.description}
        meta={ogImage}
        canonicalLink={currentTalks.metaTags.canonicalUrl}
      />
      <section className="greenBgColor">
        <div className="mainWrapperSpeakers mainGridWrapperEnterprise removePaddBottom">
          <div>
            <div className="leftSidebarWrapper">
              <Link to="/enterprisegraphql/2020/">
                <div className="brand">
                  <Lottie
                    options={defaultOptions}
                    width={80}
                    style={{ margin: 0, marginLeft: "-10px" }}
                  />
                  <img src={brand} alt="Brand" />
                </div>
              </Link>
              <div className="mobile-dates visibleMobileEnterprise">
                21st &amp; 22nd
                <br />
                Oct’20
              </div>
            </div>
          </div>
          <img
            className="speakersDetailsIllustration"
            src={speakersIllustration}
            alt="Illustration"
          />
          <div className="speakersBottomSection removePaddTop">
            <div className="zIndexOne">
              <div className="rotate">
                <div className="descEnterprise">{/*currentTalks.time*/}</div>
                <div className="sectionTitleEnterprise">{currentTalks.talkType}</div>
              </div>
            </div>
            <div className="enterprisewd65 zIndexOne ">
              <div className="descEnterprise secondaryColor">{currentTalks.talkTitleType}</div>
              <div className="enterpriseTalkDetails enterpriseBorBottomWhite">
                <h1 className="sectionTitleEnterprise primaryColor">{currentTalks.title}</h1>
                {currentTalks.description ? (
                  <Fragment>
                    {currentTalks.description.lists.map((list, index) => {
                      return (
                        <div key={index} className="descEnterprise secondaryColor">
                          {list}
                        </div>
                      );
                    })}
                    {currentTalks.description.subLists ? (
                      <ul className="descEnterprise listsPaddTop">
                        {currentTalks.description.subLists.map((subList, subIndex) => {
                          return (
                            <li key={subIndex}>
                              <div className="grayCircle"></div>
                              {subList}
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                    {currentTalks.description.listsTwo
                      ? currentTalks.description.listsTwo.map((list, listIndex) => {
                          return (
                            <div key={listIndex} className="descEnterprise">
                              {list}
                            </div>
                          );
                        })
                      : null}
                  </Fragment>
                ) : null}
              </div>
              <div className="enterpriseSpeakersWrapper">
                <div className="sectionTitleEnterprise">
                  {currentTalks.multipleSpeakers ? "Speakers" : "Speaker"}
                </div>
                <div className="enterpriseSpeakersListWrapper twoColumsWrapper">
                  {currentTalks.speakersList.map((list, index) => {
                    return (
                      <div key={index} className="speakersList twoColumsList">
                        <Link to={list.speakersUrl}>
                          <div className="speakersListContainer">
                            <div className="speakersImg">
                              <img src={list.speakersImg} alt={list.speakersName} />
                            </div>
                            <div className="speakersProfileWrapper">
                              <div className="subTitleEnterprise">{list.speakersName}</div>
                              <div className="descSmallEnterprise">{list.speakersDesignation}</div>
                            </div>
                            <div className="enterpriseArrow">
                              <img src={arrow} alt="Arrow" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="eventsBtnWrapper">
                <div className="enterpriseLine"></div>
                <Link to="/enterprisegraphql/2020/#schedule">
                  <button className="eventsBtn">
                    <img src={btnArrow} alt="Arrow" />
                    Back to Talks
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="buttonWrapper buttonPos">
        <Link to={currentTalks.url + "watch"}>
          <button className="commonBtnEnterprise">Watch Video</button>
        </Link>
      </div>
      <Footer bgColor="greenBgColor" />
    </Fragment>
  );
};

export default TalkDetails;
